// if we want preload remotes
// import { loadRemoteEntry } from '@angular-architects/module-federation';
// import { environment } from './environments/environment';
// Promise.all([
//   loadRemoteEntry({type: 'module', remoteEntry: environment.remotes.nutrition})
// ])
// .catch(err => console.error('Error loading remote entries', err))
// .then(() => import('./bootstrap'))
// .catch((err) => console.error(err));

// without preloading
import('./bootstrap').catch((err) => console.error(err));
